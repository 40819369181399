import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { StyledPaper } from '../component/shared/StyledComponents';
import { LaktacijuAmziai } from '../constants/ParametruGrupes';
import LaktacijuGrafikas from '../component/laktacijuKreives/LaktacijuGrafikas';
import LaktacijuLentele from '../component/laktacijuKreives/LaktacijuLentele';
import { Rodikliai } from '../constants/Rodikliai';
import RodiklioSelect from '../component/shared/RodiklioSelect';
import { postItem } from '../Api/Api';
import SingleSelect from '../component/shared/SingleSelect';
import LimitedMultipleSelect from '../component/shared/LimitedMultipleSelect';
import { useGetQuery } from '../hooks/useQuery';
import { useAuth } from '../context/AuthProvider';

const rodikliuPavadinimai = ['primilzis', 'bazinis'];
const rodikliai = Rodikliai.filter(
  (r) => rodikliuPavadinimai.indexOf(r.parametras) > -1
);

const LaktacjuKreives = () => {
  const auth = useAuth();
  const [laktacija, setLaktacija] = useState(0);
  const [rodiklis, setRodiklis] = useState(Rodikliai[0]);
  const [veisles, setVeisles] = useState([0]);
  const [duomenys, setDuomenys] = useState({ pikai: null, duomenys: [] });

  const veisliuList = useGetQuery('laktacijos/veisles');

  useEffect(() => {
    const body = { laktId: laktacija, veisles: veisles };
    veisles.length > 0 &&
      postItem('laktacijos/kreives', body)
        .then((data) => {
          data.data && setDuomenys(data.data);
        })
        .catch((err) => {
          console.log(err);
          setDuomenys({ pikai: null, duomenys: [] });
          if (err.response.status === 401) {
            auth.logout();
          }
        });
  }, [laktacija, veisles, auth]);

  const laktacijos = [{ id: 0, label: 'Visos', min: 1, max: 30 }].concat(
    LaktacijuAmziai
  );

  //Pakeiciam duomenu formata, kad butu imanoma nupiesti grafika
  const filtruotiDuomenys = duomenys.duomenys.map((d) => {
    let diena = {
      laktDiena: d.laktDiena,
    };

    d.veisles
      .filter((v) => veisles.indexOf(v.veisle) > -1)
      .map(
        (v) => (diena[`${v.trPavad}`] = v.parametrai[`${rodiklis.parametras}`])
      );

    return diena;
  });

  const maxReiksme =
    duomenys.pikai && veisles.length !== 0
      ? duomenys.pikai[`${rodiklis.parametras}`]
      : { diena: 0, reiksme: 0 };

  const vidReiksme =
    duomenys.vidurkiai && veisles.length !== 0
      ? duomenys.vidurkiai[`${rodiklis.parametras}`]
      : 0;

  return (
    <Container maxWidth='100vw' sx={{ mt: 4, mb: 4 }}>
      <Typography variant='h4' sx={{ marginBottom: '20px' }}>
        Visos Lietuvos laktacijų kreivės
      </Typography>
      <Grid container justifyContent='center' spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <Typography>Rodiklis</Typography>
            <RodiklioSelect
              rodiklis={rodiklis}
              rodikliuList={rodikliai}
              changeRodiklis={setRodiklis}
              fullWidth
            />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <LimitedMultipleSelect
              pavadinimas='Veislės'
              grupes={veisliuList}
              pasirinktos={veisles}
              maxRiba={5}
              changeGrupes={setVeisles}
            />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <SingleSelect
              pavadinimas='Amžius laktacijoms'
              pasirinkimai={laktacijos}
              reiksme={laktacija}
              changeReiksme={setLaktacija}
            />
          </StyledPaper>
        </Grid>
      </Grid>

      <Grid container justifyContent='center' spacing={2} height={600}>
        <Grid item xs={12} lg={9}>
          <StyledPaper sx={{ height: '100%' }}>
            <LaktacijuGrafikas
              rodiklis={rodiklis}
              duomenys={filtruotiDuomenys}
              veisles={veisles}
              pikas={maxReiksme.reiksme}
              vidurkis={vidReiksme}
              veisliuList={veisliuList}
            />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={3}>
          <LaktacijuLentele rodiklis={rodiklis} pikas={maxReiksme} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LaktacjuKreives;
