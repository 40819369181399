import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledPaper } from '../component/shared/StyledComponents';
import { Rodikliai } from '../constants/Rodikliai';
import RodiklioSelect from '../component/shared/RodiklioSelect';
import { useMenu } from '../context/MenuProvider';
import { useAuth } from '../context/AuthProvider';
import { getItem, postItem } from '../Api/Api';
import SingleSelect from '../component/shared/SingleSelect';
import LimitedMultipleSelect from '../component/shared/LimitedMultipleSelect';
import UkioLaktacijuGrafikas from '../component/laktacijuKreives/UkioLaktacijuGrafikas';
import UkioLaktacijuLentele from '../component/laktacijuKreives/UkioLaktacijuLentele';
import UkioKarviuLentele from '../component/laktacijuKreives/UkioKarviuLentele';

const rodikliuPavadinimai = ['primilzis', 'bazinis'];
const rodikliai = Rodikliai.filter(
  (r) => rodikliuPavadinimai.indexOf(r.parametras) > -1
);

const laktDienuKiekiuSuma = (duomenys, dienaNuo, dienaIki, rodiklis) => {
  try {
    let suma = 0;
    for (let i = dienaNuo - 1; i < dienaIki; i++) {
      suma += duomenys.duomenys[i].laktacijos[0].parametrai[`${rodiklis}`];
    }

    return suma.toFixed(2);
  } catch {
    return 1;
  }
};

const UkioLaktKreives = () => {
  const menu = useMenu();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [rodiklis, setRodiklis] = useState(Rodikliai[0]);
  const [veisliuList, setVeisliuList] = useState([]);
  const [veisle, setVeisle] = useState(0);
  const [laktacijos, setLaktacijos] = useState([0]);
  const [duomenys, setDuomenys] = useState({ pikai: null, duomenys: [] });
  const [karvesDuom, setKarvesDuom] = useState({
    karve: '',
    laktNr: 0,
    laktId: 0,
    laktPradzia: null,
    reiksmes: [],
  });
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const savininkas = menu.savininkas;
  const arSavininkas = auth.user.role === 'Ūkis';
  const laktacijuList =
    veisliuList.length > 0
      ? veisliuList.find((v) => v.id === veisle).laktacijos
      : [];

  useEffect(() => {
    savininkas && setIsLoading(true);
    setVeisle(0);
    setLaktacijos([0]);
    setDuomenys({ pikai: null, duomenys: [] });
    setKarvesDuom({
      karve: '',
      laktNr: 0,
      laktId: 0,
      laktPradzia: null,
      reiksmes: [],
    });
    setIsAnimationActive(true);
    (savininkas || arSavininkas) &&
      getItem(
        arSavininkas
          ? 'laktacijos/savininkai/veisles'
          : 'laktacijos/savininkai/veisles/' + savininkas
      )
        .then((data) => {
          data.data && setVeisliuList(data.data);
          setLaktacijos([0]);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setVeisliuList([]);
            setLaktacijos([]);
            setIsLoading(false);
          } else if (err.response.status === 401) {
            auth.logout();
          }
        });
    // eslint-disable-next-line
  }, [savininkas, arSavininkas]);

  useEffect(() => {
    setKarvesDuom({
      karve: '',
      laktNr: 0,
      laktId: 0,
      laktPradzia: null,
      reiksmes: [],
    });
    setIsAnimationActive(true);

    const body = {
      veisle: veisle,
      laktacijos: laktacijos,
    };

    !arSavininkas && (body.gptKodas = savininkas);

    if (savininkas || arSavininkas) {
      if (laktacijos.length > 0) {
        postItem('laktacijos/savininkai/kreives', body)
          .then((data) => {
            data.data && setDuomenys(data.data);
          })
          .catch(() => {
            setDuomenys({ pikai: null, duomenys: [] });
          });
      } else {
        setDuomenys({ pikai: null, duomenys: [] });
      }
    }
  }, [savininkas, arSavininkas, veisle, laktacijos]);

  if (veisliuList.length === 0 || duomenys.duomenys === null) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Typography>Atvaizduoti kreivėms nepakanka duomenų</Typography>
      </Box>
    );
  }

  //Pakeiciam duomenu formata, kad butu imanoma nupiesti grafika
  let filtruotiDuomenys = duomenys.duomenys.map((d) => {
    let diena = {
      laktDiena: d.laktDiena,
    };

    d.laktacijos
      .filter((l) => laktacijos.indexOf(l.id) > -1)
      .map(
        (l) => (diena[`${l.trPavad}`] = l.parametrai[`${rodiklis.parametras}`])
      );

    return diena;
  });

  if (karvesDuom.karve !== '') {
    filtruotiDuomenys = filtruotiDuomenys.concat(
      karvesDuom.reiksmes.map((d) => {
        const duom = { laktDiena: d.laktDiena };
        duom[karvesDuom.karve] = d[`${rodiklis.parametras}`];

        return duom;
      })
    );
  }

  const maxReiksme = duomenys.pikai
    ? duomenys.pikai[`${rodiklis.parametras}`]
    : { diena: 0, reiksme: 0 };

  const kiekis90diena =
    laktacijos.length === 1
      ? duomenys.duomenys[85] &&
        duomenys.duomenys[85].laktacijos[0].parametrai[`${rodiklis.parametras}`]
      : '--';

  const suma100diena =
    laktacijos.length === 1
      ? laktDienuKiekiuSuma(duomenys, 1, 100, rodiklis.parametras)
      : 0;

  const suma200diena =
    laktacijos.length === 1
      ? laktDienuKiekiuSuma(duomenys, 101, 200, rodiklis.parametras)
      : 0;

  const santykis =
    laktacijos.length === 1
      ? ((suma200diena / suma100diena) * 100).toFixed(2)
      : '--';

  const vidReiksme = duomenys.vidurkiai
    ? duomenys.vidurkiai[`${rodiklis.parametras}`]
    : 0;

  return (
    <Container maxWidth='100vw' sx={{ mt: 4, mb: 4 }}>
      <Typography variant='h4' sx={{ marginBottom: '20px' }}>
        Ūkio laktacijų kreivės
      </Typography>
      <Grid container spacing={1}>
        <Grid container item justifyContent='center' spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={4}>
            <StyledPaper>
              <Typography>Rodiklis</Typography>
              <RodiklioSelect
                rodiklis={rodiklis}
                rodikliuList={rodikliai}
                changeRodiklis={setRodiklis}
                fullWidth
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <StyledPaper>
              <SingleSelect
                pavadinimas='Veislės'
                pasirinkimai={veisliuList}
                reiksme={veisle}
                changeReiksme={setVeisle}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <StyledPaper>
              <LimitedMultipleSelect
                pavadinimas='Laktacijos'
                grupes={laktacijuList}
                pasirinktos={laktacijos}
                changeGrupes={setLaktacijos}
              />
            </StyledPaper>
          </Grid>
        </Grid>

        <Grid
          container
          item
          justifyContent='center'
          spacing={2}
          sx={{ marginBottom: '40px' }}
        >
          <Grid item xs={12} lg={9}>
            <StyledPaper sx={{ height: 500 }}>
              <UkioLaktacijuGrafikas
                isLoading={isLoading}
                rodiklis={rodiklis}
                duomenys={filtruotiDuomenys}
                laktacijos={laktacijos}
                pikas={maxReiksme.reiksme}
                vidurkis={vidReiksme}
                laktacijuList={laktacijuList}
                karve={karvesDuom.karve}
                lineAnimation={isAnimationActive}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={3}>
            <UkioLaktacijuLentele
              pikas={maxReiksme}
              reiksme90={kiekis90diena}
              santykis={santykis}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent='center'
        spacing={2}
        height={300}
        sx={{ marginBottom: '40px' }}
      >
        <Grid item xs={12} lg={12}>
          <UkioKarviuLentele
            veisle={veisle}
            laktacijos={laktacijos}
            karve={{ karve: karvesDuom.karve, laktNr: karvesDuom.laktNr }}
            changeKarve={setKarvesDuom}
            changeAnimation={setIsAnimationActive}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UkioLaktKreives;
