import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
  Legend,
} from 'recharts';
import { Spalvos } from '../../constants/Spalvos';
import { StyledLabel, StyledTooltip } from '../shared/StyledComponents';

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`Laktacijos diena: ${payload[0].payload.laktDiena}`}</StyledLabel>
        {payload.map((p) => (
          <StyledLabel
            key={p.name}
            color={p.color}
          >{`${p.name}: ${p.value}`}</StyledLabel>
        ))}
      </StyledTooltip>
    );
  }

  return null;
};

const LaktacijuGrafikas = ({
  isLoading,
  rodiklis,
  duomenys,
  veisles,
  pikas,
  vidurkis,
  veisliuList,
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography>Duomenys kraunami...</Typography>
      </Box>
    );
  }

  const pasirinktosVeisles = veisliuList.filter(
    (v) => veisles.indexOf(v.id) > -1
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Typography>
        <b>Laktacijos kreivė</b>
      </Typography>
      <Box sx={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={duomenys}
            margin={{ bottom: 16, left: 15, right: 10 }}
          >
            <CartesianGrid />
            <XAxis
              type='number'
              dataKey='laktDiena'
              label={{
                value: 'Laktacijos diena',
                position: 'insideBottom',
                offset: -10,
              }}
              ticks={[100, 200, 300]}
            />
            <YAxis
              name={rodiklis.pavadinimas}
              unit={rodiklis.vienetai}
              label={{
                value: rodiklis.pavadinimas,
                angle: -90,
                position: 'insideLeft',
                offset: -5,
              }}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip pavadinimas={rodiklis.pavadinimas} />}
            />
            <Legend
              verticalAlign='top'
              wrapperStyle={{ paddingBottom: '20px' }}
            />
            {pikas > 0 && (
              <ReferenceLine
                y={pikas}
                stroke='red'
                strokeDasharray='3 3'
                ifOverflow='extendDomain'
              >
                <Label
                  stroke='red'
                  strokeOpacity={0.7}
                  position='insideBottomRight'
                  value={`Maksimali reikšmė: ${pikas}`}
                />
              </ReferenceLine>
            )}
            {vidurkis > 0 && (
              <ReferenceLine
                y={vidurkis}
                stroke='black'
                strokeDasharray='3 3'
                ifOverflow='extendDomain'
              >
                <Label
                  stroke='black'
                  strokeOpacity={0.7}
                  position='insideBottomRight'
                  value={`Vidurkis: ${vidurkis}`}
                />
              </ReferenceLine>
            )}
            {pasirinktosVeisles.map((d, index) => (
              <Line
                key={d.id}
                name={veisliuList.find((v) => v.id === d.id).label}
                type='monotone'
                dataKey={d.trPavad}
                stroke={Spalvos[index]}
                dot={false}
                strokeWidth={6}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default LaktacijuGrafikas;
