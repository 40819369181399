import React from 'react';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { Box, IconButton, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Primary } from '../../constants/Spalvos';

const ExportExcel = ({ data, columns, widths, fileName, align }) => {
  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    utils.sheet_add_aoa(worksheet, [columns], { origin: 'A1' });
    widths && (worksheet['!cols'] = widths.map((w) => ({ wch: w })));
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Box display='flex' justifyContent={align ? align : 'flex-start'}>
      <IconButton onClick={exportToExcel} sx={{ color: Primary, padding: 0 }}>
        <Typography>Atsisiųsti duomenis</Typography>
        <FileDownloadIcon />
      </IconButton>
    </Box>
  );
};

export default ExportExcel;
